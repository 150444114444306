<template>
  <div class="page-block">

    <i18n-selector />

    <div class="page-block-item">
      <div class="group-logo">
        <div><img class="client-logo" :src="getClientLogo()"></div>
        <div><img class="zhh-logo" src="/vaccine/images/logo/zhh_group_logo.png"></div>
      </div>
      <p v-if="showLoginWelcome()" class="page-title">{{ getLoginWelcome() }}</p>
      <p class="general-text">{{ getDisclaimer() }}</p>
      <p v-if="authType === 'EMAIL'" class="general-text">
        {{ geLoginInstruction_employeeEmail() }}
      </p>
      <p v-else class="general-text">
        {{ getLoginInstruction_employeeId() }}
        <br><br>
        {{ getLoginWarning_employeeId() }}
      </p>
      <div v-if="clientId === 'flynnrg'">
        <center><p class="general-text"><a href="https://www.flynnrestaurantgroup.com/workplace-privacy-notice/" target="_blank">Privacy Policy</a><br></p></center>
      </div>
      <div class="page-block-form">
        <form v-on:submit="login">
          <div v-if="authType === 'EMP_ID'">
            <input class="w-input" type="text" v-model="employeeId" />
          </div>
          <div v-if="authType === 'EMAIL'">
            <input class="w-input" type="email" v-model="employeeEmail" />
          </div>
          <input v-if="!submitting" class="button w-button" type="submit" :value="$t('login')" />
          <span v-else class="button w-button">
            <div class="lds-loader"><div></div><div></div><div></div></div>
          </span>
          <div v-if="getSSO()" style="margin-top: 25px">
            <p class="general-text">
              {{ getSSOInstructions() }}
            </p>
            <a class="button w-button sso-button" :href="getSSO()">
              <span class="fas fa-home mr-2"></span>{{ getSSOLabel() }}
            </a>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import AuthApi from '@/api/auth.api';
import Utils from '@/utils/utils';

const CLIENT = Utils.getClient();

export default {
  name: 'Login',
  data() {
    return {
      authType: undefined,
      employeeId: '',
      employeeEmail: '',
      submitting: false,
      clientId: CLIENT,
    };
  },

  created() {
    const authFailed = this.$route.params?.authFailed;
    if (authFailed) {
      let message = this.$t('invalidToken');
      if (authFailed.reason === 'expired-token') {
        message = this.$t('expiredToken');
      } else if (authFailed.reason === 'no-token') {
        message = this.$t('notFoundToken');
      }

      this.$swal({
        text: message,
        icon: 'warning',
      });
    }
    this.authType = this.$route.params.authType;
  },

  methods: {
    getClientLogo() {
      return `/vaccine/images/logo/${CLIENT}.png`;
    },

    getLoginAuthData() {
      const data = {};

      if (this.authType === 'EMP_ID') {
        data.employeeId = this.employeeId.trim();
      }

      if (this.authType === 'EMAIL') {
        data.employeeEmail = this.employeeEmail.trim();
      }

      return data;
    },

    getLoginWelcome() {
      if (CLIENT === 'sodexo') {
        return this.$t('loginWelcomeSodexo');
      }
      return this.$t('loginWelcome');
    },

    geLoginInstruction_employeeEmail() {
      if (CLIENT === 'blazepizza'
          || CLIENT === 'blackbeardiner'
          || CLIENT === 'windham'
          || CLIENT === 'saucepizzaandwine'
          || CLIENT === 'threebbd') {
        return this.$t('loginInstruction_employeeEmailForBlaze');
      }
      return this.$t('loginInstruction_employeeEmail');
    },

    getLoginInstruction_employeeId() {
      if (CLIENT === 'citybbq') {
        return this.$t('loginInstruction_employeeIdCitybbq');
      }
      if (CLIENT === 'brinker') {
        return this.$t('loginInstruction_employeeIdForBrinker');
      }
      if (CLIENT === 'sodexo') {
        return this.$t('loginInstruction_employeeIdSodexo');
      }
      if (CLIENT === 'flynnrg') {
        return this.$t('loginInstruction_employeeIdForFlynnrg');
      }
      if (CLIENT === 'callaway') {
        return this.$t('loginInstruction_employeeIdCallaway');
      }
      return this.$t('loginInstruction_employeeId');
    },

    getDisclaimer() {
      if (CLIENT === 'blackbeardiner') {
        return this.$t('disclaimerBlackbear');
      }
      if (CLIENT === 'tandmassociates') {
        return this.$t('disclaimerTAndM');
      }
      if (CLIENT === 'sodexo') {
        return '';
      }
      if (CLIENT === 'flynnrg') {
        return this.$t('disclaimerFlynnrg');
      }
      return this.$t('disclaimer');
    },

    getLoginWarning_employeeId() {
      if (CLIENT === 'citybbq') {
        return this.$t('loginWarning_employeeIdCitybbq');
      }
      if (CLIENT === 'sodexo') {
        return '';
      }
      return this.$t('loginWarning_employeeId');
    },

    showLoginWelcome() {
      if (CLIENT === 'tandmassociates') {
        return false;
      }
      return true;
    },

    getSSOInstructions() {
      if (CLIENT === 'sodexo') {
        return this.$t('managerButtonInstructionsSodexo');
      }
      if (CLIENT === 'raisingcanes') {
        return this.$t('managerButtonInstructionsRaisingcanes');
      }
      return '';
    },

    getSSOLabel() {
      if (CLIENT === 'sodexo') {
        return this.$t('managerButtonLabelSodexo');
      }
      if (CLIENT === 'raisingcanes') {
        return this.$t('managerButtonLabelRaisingcanes');
      }
      return '';
    },

    async login(event) {
      this.submitting = true;
      event.preventDefault();
      const data = this.getLoginAuthData();

      const warningMessage = this.authType === 'EMAIL' ? this.$t('loginPage_missingEmployeeEmail') : this.$t('loginPage_missingEmployeeId');
      if (!data.employeeId && !data.employeeEmail) {
        this.submitting = false;
        this.$swal({
          text: warningMessage,
          icon: 'warning',
        });
        return;
      }

      if (data.employeeEmail
          && (data.employeeEmail.includes('%') || data.employeeEmail.includes('/'))) {
        this.submitting = false;
        this.$swal({
          text: this.$t('loginPage_notAllowedEmployeeEmailCharacterWarning'),
          icon: 'warning',
        });
        return;
      }

      try {
        const authResponse = await AuthApi.logIn(data);
        if (authResponse.status === 'success') {
          this.submitting = false;
          const authByEmail = authResponse.data?.autheticationByEmail;
          if (authByEmail) {
            this.$router.push({ name: 'LoginEmailPage' });
          } else {
            this.$router.push({ name: 'HomePage' });
          }
        }
      } catch (e) {
        this.submitting = false;
        // console.error('Unauthorised');
        const options = {
          title: 'Authentication Failed.',
          text: 'User not authorized!',
          icon: 'error',
        };
        this.$swal(options);
      }
    },

    getSSO() {
      if (CLIENT === 'sodexo' || CLIENT === 'raisingcanes') {
        return `${Utils.getBaseURL()}/auth/${CLIENT}/sso/saml`;
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
  form {
     text-align: center;
  }

  .page-block-item {
    width: 600px;
  }

  .page-block-form {
    width: 300px;
    margin: auto;
  }

  .w-button {
    width: 300px;
  }

  .lds-loader {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-loader div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: #fff;
    animation: lds-loader 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    border: 1px solid #5031D0;
  }
  .lds-loader div:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
  }
  .lds-loader div:nth-child(2) {
    left: 32px;
    animation-delay: -0.12s;
  }
  .lds-loader div:nth-child(3) {
    left: 56px;
    animation-delay: 0;
  }

  .group-logo {
    display: block;
    text-align: center;
  }

  .client-logo {
    margin-bottom: 30px;
  }

  .zhh-logo {
    margin-bottom: 75px;
  }

  .group-logo .client-logo {
    width: 300px;
    height: auto;
  }

  .margin-bottom-15 {
    margin-bottom: 15px;
  }

  .sso-button {
    margin-top: 10px;
    margin-bottom: 15px;
    background-color: #ffffff;
    border: 1px solid #5031D0;
    color: #5031D0;
  }

  @keyframes lds-loader {
    0% {
      top: 8px;
      height: 64px;
    }
    50%, 100% {
      top: 24px;
      height: 32px;
    }
  }

  @media screen and (max-width: 479px) {
    .page-block-item {
      width: 100%;
    }

    .zhh-logo {
      margin-bottom: 50px;
    }

    .w-button {
      width: 100%;
    }

    .w-input {
      width: 100%;
      font-size: 16px;
    }

  }

</style>
